<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    v-model="showModal"
    :click-to-close="false"
  >
    <!-- layer : 텍스트 항목 추가 -->
    <section id="modalAddText" class="layer_dialog">
      <h2>이미지 항목 추가 - 타이틀 입력</h2>
      <p class="txt">관리하실때 인지하기 쉬운 타이틀을 입력해주세요.</p>
      <input type="text" name="" v-model.trim="title" style="width: 100%" />
      <div class="btns">
        <ul>
          <li>
            <button
              type="button"
              class="btn btn_large btn_secondary"
              @click="close"
            >
              <span>닫기</span>
            </button>
          </li>
          <li>
            <button
              type="button"
              class="btn btn_large btn_primary"
              @click="add"
            >
              <span>저장</span>
            </button>
          </li>
        </ul>
      </div>
    </section>
    <!-- //layer : 텍스트 항목 추가 -->
  </vue-final-modal>
</template>

<script>
import { mapState } from 'vuex';
import common from '@/components/mixin/index.js';

export default {
  mixins: [common],
  inheritAttrs: false,
  data: () => ({
    showModal: true,
    id: 0,
    result_items: [],
    title: '',
  }),
  props: ['params'],
  created: async function () {
    this.init();
  },
  computed: {
    ...mapState('qna_config', ['qna_config']),
  },
  mounted() {},
  methods: {
    init: function () {
      if (this.params.result_item !== '') {
        this.result_items = JSON.parse(this.params.result_item);
      }
    },
    add: async function () {
      if (
        this.title === null ||
        this.title === '' ||
        this.title === undefined
      ) {
        alert('타이틀을 입력해주세요.');
        return false;
      } else if (this.title.length >= 50) {
        alert('타이틀은 50자 이내로 입력해주세요.');
        return false;
      }

      this.result_items.push({
        type: 'text',
        title: this.title,
      });

      this.params.result_item = JSON.stringify(this.result_items);
      const result = await this.$store.dispatch(
        'qna_config/postData',
        this.params,
      );

      if (result === true) {
        alert('저장되었습니다.');
        this.$emit('updateResultItems', this.params.result_item);
        this.showModal = false;
      } else {
        alert('저장을 실패하였습니다.');
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.upscreen {
  transform: none;
}
</style>
